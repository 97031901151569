



import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const $request = function (name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		//		/#/asdf?openid=123&appid=456
		//		/?openid=123&appid=456#/asdf
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}

	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}

const router = new Router({
	routes: [{
		path: '/',
		redirect: "/index"
	},

	//		{
	//			path: '*',
	//			redirect: "/index",
	//		},
	//首页
	{
		path: '/main',
		component: resolve => require(['../views/home.vue'], resolve),
		children: [
			//首页分类等
			{
				path: '/index',
				component: resolve => require(['../views/Index.vue'], resolve)
			},
			{
				path: '/home',
				redirect: "/index"
			},
			{
				path: '/prolist',
				component: resolve => require(['../views/proList.vue'], resolve)
			}, {
				path: '/cataloglist/:id',
				component: resolve => require(['../views/catalogList.vue'], resolve)
			},
			//我的
			{
				path: '/my',
				component: resolve => require(['../views/myCenter.vue'], resolve),
			},
			//我的个人资料
			{
				path: '/myinfo',
				component: resolve => require(['../views/myInfo.vue'], resolve),
			},
			//我邀请的好友
			{
				path: '/myinvite',
				component: resolve => require(['../views/myInvite.vue'], resolve),
			},
			{
				path: '/heinvite/:id',
				component: resolve => require(['../views/heInvite.vue'], resolve),
			},
			//订单
			{
				path: '/order',
				component: resolve => require(['../views/orderList.vue'], resolve),
			},
			//订单详情
			{
				path: '/orderdetail',
				component: resolve => require(['../views/orderDetail.vue'], resolve),
			},
			//购物车
			{
				path: '/cart',
				component: resolve => require(['../views/cartList.vue'], resolve),
			},
			//购买成功
			{
				path: '/buysuccess',
				component: resolve => require(['../views/buySuccess.vue'], resolve),
			},
			//退换货说明
			{
				path: '/returnpro',
				component: resolve => require(['../views/returnPro.vue'], resolve),
			},
			//积分使用
			{
				path: '/pointchange',
				component: resolve => require(['../views/pointChange.vue'], resolve),
			}
		]
	},
	{
		path: '/coupon',
		component: resolve => require(['../views/coupon.vue'], resolve),
	},
	//购物车下单
	{
		path: '/cartbuy',
		component: resolve => require(['../views/cartBuy.vue'], resolve),
	},
	{
		path: '/onebuy',
		component: resolve => require(['../views/oneBuy.vue'], resolve),
	},
	{
		path: '/address',
		component: resolve => require(['../views/address.vue'], resolve),
	},
	//商品详情
	{
		path: '/prodetail/:id',
		component: resolve => require(['../views/proDetail.vue'], resolve),
	},
	{
		path: '/handbook/:id',
		component: resolve => require(['../views/handbook.vue'], resolve),
	},
	{
		path: '/give',
		component: resolve => require(['../views/give.vue'], resolve),
	},
	// {
	// 	path: '/twobuy',
	// 	component: resolve => require(['../views/twobuy.vue'], resolve),
	// },
	//社区
	{
		path: '/community/:id',
		component: resolve => require(['../views/community/community.vue'], resolve),
	},
	{
		path: '/subindex/:id',
		component: resolve => require(['../views/community/subindex.vue'], resolve),
	},
	{
		path: '/comindex',
		component: resolve => require(['../views/community/Index.vue'], resolve),
	},
	{
		path: '/article/:id',
		component: resolve => require(['../views/community/article.vue'], resolve),
	},
	{
		path: '/message',
		component: resolve => require(['../views/message.vue'], resolve),
	},
	{
		path: '/comment',
		component: resolve => require(['../views/comment.vue'], resolve),
	}, {
		path: '/friendinfo/:id',
		component: resolve => require(['../views/friendinfo.vue'], resolve),
	}, {
		path: '/videolist',
		component: resolve => require(['../views/videolist.vue'], resolve),
	}, {
		path: '/videoplay/:id',
		component: resolve => require(['../views/videoplay.vue'], resolve),
	}, {
		path: '/zjlist',
		component: resolve => require(['../views/zhuanjia/zjlist.vue'], resolve),
	}, {
		path: '/zhuanjia/:id',
		component: resolve => require(['../views/zhuanjia/zhuanjia.vue'], resolve),
	}, {
		path: '/zxbuy',
		component: resolve => require(['../views/zhuanjia/zxbuy.vue'], resolve),
	}, {
		path: '/zxwrite/:id',
		component: resolve => require(['../views/zhuanjia/zxwrite.vue'], resolve),
	}, {
		path: '/zxmy',
		component: resolve => require(['../views/zhuanjia/zxmy.vue'], resolve),
	}, {
		path: '/zxreply/:id',
		component: resolve => require(['../views/zhuanjia/zxreply.vue'], resolve),
	}, {
		path: '/zxinfo/:id',
		component: resolve => require(['../views/zhuanjia/zxreply.vue'], resolve),
	}, {
		path: '/zxresult/:id',
		component: resolve => require(['../views/zhuanjia/zxresult.vue'], resolve),
	}, {
		path: '/zjcenter',
		component: resolve => require(['../views/zhuanjia/zjcenter.vue'], resolve),
	},
	{
		path: '/zxshouyi',
		component: resolve => require(['../views/zhuanjia/zxshouyi.vue'], resolve),
	},
	{
		path: '/zxtixian',
		component: resolve => require(['../views/zhuanjia/zxtixian.vue'], resolve),
	},
	{
		path: '/zxzjreplylist',
		component: resolve => require(['../views/zhuanjia/zxzjreplylist.vue'], resolve),
	}, {
		path: '/wj/:id',
		component: resolve => require(['../views/wj/wj.vue'], resolve),
	},
	{
		path: '/qedit',
		component: resolve => require(['../views/zhuanjia/qedit.vue'], resolve),
	},
	]
})

router.beforeEach((to, from, next) => {
	//检查是否授权
	//	if(window.location.href.indexOf("token")>0){
	//		
	//		alert(window.location.href)
	//		return
	//	}
	let token = $request("token");
	if (!token) {
		token = localStorage.getItem("token")
		if (token) {
			next();
		} else {
			var hash = window.location.hash;
			hash = hash.split("?")[0];
			console.log(window._browser)
			if (window.mobile) {
				setTimeout(() => {
					window.location.href = "//api.guominguominbaby.cn/auth.aspx?target=http://www.guominguominbaby.cn&hash=" + window.location.hash.replace("#", "");
				}, 500)
			}else{
				next();
			}



		}

	} else {

		localStorage.setItem("token", token);
		var hash = window.location.hash;
		hash = hash.split("?")[0];
		setTimeout(() => {
			window.location.href = "http://www.guominguominbaby.cn/" + hash;
		}, 500)

		next();
	}

	return
	if (token) {
		localStorage.setItem("token", token);
		next();
	} else {
		if (!localStorage.getItem("token")) {
			window.location.href = "//api.guominguominbaby.cn/auth.aspx?target=https://" + window.location.host + "&hash=" + window.location.hash.replace("#", "");

		} else {
			next();
		}

		//next(); //接口对接好以后删掉
	}

})
export default router
