<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'app'
	}
</script>

<style>
	/*导入全局的样式文件*/
	@import "./common/css/main.css";

	.ql-toolbar {
		border: 0 !important;
		display: none !important;
	}

	.ql-container.ql-snow {
		border: 0px solid #ccc !important;
		background-color: #FFFFFF;
		color: #000000;
	}

	iframe {
		width: 100%;
		height: auto !important;
	}
</style>
